import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Partner, Props as PartnerProps } from './Partner'

export interface Props {
  description?: string
  partners: PartnerProps[]
  title?: string
}

export const Partners = memo(function Partners({
  description,
  partners,
  title,
}: Props) {
  if (!partners) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}

      <List>
        {partners.map((item, index) => (
          <Partner key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  margin: 15.25rem auto 12.5rem;
  text-align: center;

  @media (max-width: 767px) {
    margin: 8.6875rem auto 7.5rem;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    padding: 0 1.875rem;
  }

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  max-width: 47.25rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin: 1.875rem auto 0;

  @media (max-width: 1023px) {
    display: none;
  }
`

const List = styled.div`
  margin-top: 9rem;

  @media (max-width: 1023px) {
    margin-top: 3.5rem;
  }
`
