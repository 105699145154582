import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  languageCode: string
  title?: string
  website?: string
}

export const Partner = memo(function Partner({
  description,
  image,
  languageCode,
  title,
  website,
}: Props) {
  return (
    <Container row wrap>
      <LeftSide dial={5}>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>

      <RightSide>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        {website ? (
          <CTA
            label={useVocabularyData('find-out-more', languageCode)}
            rel="noopener"
            target="_blank"
            URL={website}
            variant="simple"
          />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  border-top: 0.0625rem solid
    ${({ theme }) => theme.colors.variants.neutralLight0};
  margin-top: 3.75rem;
  padding: 3.75rem 11.944vw 0;
  text-align: left;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    padding: 2.5rem 1.875rem 0;
    margin-top: 5rem;
  }
`

const LeftSide = styled(FlexBox)`
  width: 13.889vw;
  height: 13.889vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-right: 4.1875rem;
  position: relative;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 991px) {
    width: 16.549vw;
    height: 16.549vw;
  }

  @media (max-width: 767px) {
    width: 28.522vw;
    height: 28.522vw;
    margin-bottom: 2.75rem;
  }

  @media (max-width: 574px) {
    width: 39.614vw;
    height: 39.614vw;
  }
`

const RightSide = styled.div`
  flex: 1;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.25rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    &:before {
      right: 50%;
    }
    &:after {
      left: 50%;
    }
  }
`
